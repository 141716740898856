import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function RulesModal({show, handleClose}) {

    return (
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>Правила проживания</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 className="mb-2">Уважаемые гости Apartments.kg!</h5>
                <p>Мы рады вас приветствовать и просим уделить внимание следующим правилам для комфортного проживания:</p>
                <ul>
                    <li><strong>Бронирование квартиры:</strong></li>
                    <ul>
                        <li>При заезде до 20:00 требуется предоплата в размере 1000 сом.</li>
                        <li>При заезде после 20:00 необходимо оплатить полную стоимость проживания.</li>
                        <li>Предоплата не возвращается в случае отмены бронирования.</li>
                    </ul>
                    <li><strong>Документы и залог:</strong></li>
                    <ul>
                        <li>При заселении предъявите паспорт или водительские права.</li>
                        <li>Возможен вариант оставления залоговой суммы.</li>
                        <li>Документы или залог остаются у нас на время проживания.</li>
                    </ul>
                    <li><strong>Курение:</strong></li>
                    <ul>
                        <li>Курение запрещенно. Исключение только для квартир с балконом,и только на балконе.</li>
                    </ul>
                    <li><strong>Соблюдение тишины:</strong></li>
                    <ul>
                        <li>Уважайте спокойствие соседей, избегайте громких звуков, особенно с 22:00 до 10:00.</li>
                    </ul>
                    <li><strong>Шумные мероприятия:</strong></li>
                    <ul>
                        <li>Использование квартиры для шумных вечеринок приведет к немедленному выселению без возврата оплаты.</li>
                    </ul>
                    <li><strong>Использование имущества:</strong></li>
                    <ul>
                        <li>Бережно относитесь к имуществу квартиры. Не переставляйте мебель и не выносите предметы из квартиры.</li>
                        <li>Штрафы за повреждения: 1000 сом за испорченное постельное бельё; стоимость ущерба за разбитую посуду, утерю или поломку ключа.</li>
                    </ul>
                    <li><strong>Продолжительность проживания и дополнительные часы:</strong></li>
                    <ul>
                        <li>Ночь считается с 20:00 до 10:00. Продление на 1 час - 200-300 сом.</li>
                        <li>Заезд на сутки до 16:00 считается как 24 часа. При заезде после 16:00 выезд на следующий день в 16:00.</li>
                    </ul>
                    <li><strong>Ограничения по проживанию:</strong></li>
                    <ul>
                        <li>Квартира предназначена для пары, семьи с детьми.</li>
                        <li>Возрастное ограничение: 18+.</li>
                    </ul>
                </ul>
                <p>Мы желаем вам приятного и комфортного пребывания в наших апартаментах!</p>
                <p>С уважением,<br/>Команда Apartments.kg</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className='p-3' onClick={handleClose}>
                    Согласен
                </Button>
            </Modal.Footer>
        </Modal>
);
}

export default RulesModal;
