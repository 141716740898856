import React, {useState} from "react";
import logoImage from '../../images/welcome/logo_house.png';
import downImage from '../../images/welcome/down.png';
import AlertInfo from "../AlertInfo/AlertInfo";

function Welcome() {
    const phones = [
        '551449945',
        '505449945'
    ];
    const domain = 'APARTMENTS.KG';

    const [showModal, setShowModal] = useState(false);

    const openModal = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div className='welcome text-center'>
            <AlertInfo show={showModal} handleClose={closeModal} />
            <div className='welcome__logo mt-2'>
                <div className='row'>
                    <div className='col-6'>

                        <img
                            style={{maxHeight: '75px'}}
                            src={logoImage}
                            alt="Логотип"/>
                        <a className='h4 d-block text-success link-underline-dark' href={`/`}>{domain}</a>
                    </div>
                    <div className='col-6'>
                        {phones.map(phone => <a key={phone} className='h5 text-success d-block mt-2 link-underline-dark' href={`tel:+996${phone}`}>{'+996 '+phone}</a>)}
                        <a href={`tel:+996${phones[0]}`} className='btn btn-success'><h5 className='pt-1'>Позвонить</h5></a>
                    </div>
                </div>
            </div>
            <div className='welcome__content mt-2'>
                    <h3 className='text-success'>Квартиры посуточно в Бишкеке</h3>
                    <h5>
                        <span style={{color:'white'}}>
                            {/*Наши квартиры - это идеальное место для вашего отдыха. <br/>*/}
                            {/*Подберем вам от бюджетных<br/>*/}
                            {/*вариантов до VIP элиток<br/>*/}
                            {/*Мы собственники!*/}
                            {/*<br/>*/}
                            От бюджетных вариантов<br/>
                            до PREMIUM элиток<br/>
                            <span style={{color:'red'}}>
                                Скидки при проживании<br/>
                                 от 3 дней 5% 10% 15%</span>
                            {/*Бронирование только по предоплате<br/>*/}
                            {/*Почасовая сдача квартир<br/>*/}
                            {/*с 11:00 до 20:00<br/>*/}
                            {/*после оплата как за ночь<br/>*/}
                            {/*Наши квартиры - это искусство уютного проживания. Современный дизайн, теплые оттенки и функциональное оборудование создают атмосферу, в которой вы почувствуете себя как дома. Проведите незабываемое время в наших уютных апартаментах*/}
                            {/*Современный дизайн, комфорт и удобства сделают ваше пребывание незабываемым<br/>*/}

                        </span>

                    </h5>
                <a
                    href='#apartments'
                    className='btn btn-success mt-2'
                    style={{height:'60px'}}
                >
                    <h4 className='mt-2 '>Посмотреть квартиры</h4>
                </a>
                <a        href={'/'}
                          className='btn btn-danger mt-2 ml-sm-3'
                          style={{height:'60px'}}
                          onClick={e => openModal(e)}
                >
                    <h4 className='mt-2'>Правила проживания</h4>
                </a>
                <img
                    style={{height: '50px'}}
                    className='mt-3 d-block mx-auto mb-n3'
                    src={downImage}
                    alt="down"
                />

            </div>

        </div>
    )
}

export default Welcome;