import React, {forwardRef, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru'
import {format} from 'date-fns'

const DateTimeModal = ({show, setShow, apartment}) => {

    const [startDate, setStartDate] = useState(new Date());

    const [endDate, setEndDate] = useState(new Date());
    const [message, setMessage] = useState('');
    const [selectedValue, setSelectedValue] = useState("Нас двое"); // Начальное значение

    const updateDate = (time, date) => {

        if (time === 'start') {
            setStartDate(date);
            setMessage(`Здравствуйте, планирую забронировать квартиру под номером ${apartment.numberkv} по адресу ${apartment.address}` +

                `\nС ${format(date, "d MMMM, EEEE, в HH:mm", {locale: ru})}` +
                `\nДО ${format(endDate, "d MMMM, EEEE, в HH:mm", {locale: ru})}` +
                `\n${selectedValue}`)
        } else {
            setEndDate(date)
            setMessage(`Здравствуйте, планирую забронировать квартиру под номером ${apartment.numberkv} по адресу ${apartment.address}` +

                `\nС ${format(startDate, "d MMMM, EEEE, в HH:mm", {locale: ru})}` +
                `\nДО ${format(date, "d MMMM, EEEE, в HH:mm", {locale: ru})}` +
                `\n${selectedValue}`)
        }

    }


    const CustomInput = forwardRef(({value, onClick, start}, ref) => {
        return (
            <button
                className="custom-datepicker-input btn btn-info"
                onClick={onClick}
                ref={ref}
            >
                {start ? format(startDate, "d MMMM, EEEE, в HH:mm", {locale: ru}) :
                    format(endDate, "d MMMM, EEEE, в HH:mm", {locale: ru})}

            </button>
        )

    });


    // Обработчик изменения выбора в select
    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
        setMessage(`Здравствуйте, планирую забронировать квартиру под номером ${apartment.numberkv} по адресу ${apartment.address}` +
            `\nС ${format(startDate, "d MMMM, EEEE, в HH:mm", {locale: ru})}` +
            `\nДО ${format(endDate, "d MMMM, EEEE, в HH:mm", {locale: ru})}` +
            `\n${event.target.value}`)
};
return (
    <>
        <Modal scrollable={true} size="sm-down" show={show} onHide={setShow} style={{overflow: 'hidden'}}>
            <Modal.Header closeButton>
                <Modal.Title>Бронирование</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center'>


                <div className='text-center mb-2'>
                    <img
                        src={'https://apartments.kg/' + apartment.imagesName[0]}
                        alt={apartment.id}
                        width='180px'
                        height='180px'
                    />
                </div>
                <h3>Выберите дату и время</h3>
                <div>
                    <span>С &nbsp;&nbsp;&nbsp; </span>
                    <DatePicker
                        selected={startDate}
                        showTimeSelect
                        onChange={(date) => updateDate('start', date)}
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="Время"
                        dateFormat="d MMMM, EEEE , в HH:mm"
                        popperPlacement="bottom-end"
                        locale={ru} // Для русского языка может потребоваться дополнительная настройка
                        customInput={<CustomInput start={true}/>}
                    />
                </div>
                <div className='mt-2'>
                    <span>До &nbsp;</span>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => updateDate('end', date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="Время"
                        popperPlacement="bottom-end"
                        dateFormat="d MMMM, EEEE , в HH:mm"
                        locale={ru} // Для русского языка может потребоваться дополнительная настройка
                        customInput={<CustomInput start={false}/>}
                    />
                </div>
                <div className='form-floating mt-2'>
                    <select
                        className="form-select"
                        id="floatingSelect"
                        aria-label="Floating label select"
                        value={selectedValue} // Привязываем состояние к value
                        onChange={handleSelectChange} // Устанавливаем обработчик на изменение
                    >
                        <option value="Я один">1</option>
                        <option value="Нас двое">2</option>
                        <option value="У нас семья из 3 человек">Семья</option>
                    </select>
                    <label htmlFor="floatingSelect">Количество человек</label>
                </div>
                <div>
                        <textarea className='mt-3 w-100 form-control-sm min-vh-50' style={{minHeight: '130px'}}
                                  value={message}
                                  readOnly/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={e => setShow()}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={e => setShow()}>
                    <a
                        // onClick={ () => window['gtag_report_conversion']()}
                        href={`https://api.whatsapp.com/send?phone=${apartment.phone}&text=${window.encodeURIComponent(message)}&source=&data=`}
                        className='link-underline-primary text-white'>
                        Забронировать
                    </a>
                </Button>
            </Modal.Footer>
        </Modal>
    </>
);
}
;

export default DateTimeModal;
