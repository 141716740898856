import React from 'react';
import Welcome from "./components/Welcome/Welcome";
import Apartments from "./components/Apartments/Apartmnets";
import AddApartments from "./components/AddApartments/AddApartments";
import AlertInfo from "./components/AlertInfo/AlertInfo";

function App() {
    return (
        <div className='container-fluid'>
            <Welcome/>
            <AlertInfo/>
            <Apartments />
            {/*<AddApartments/>*/}
{/*Добавить кнопку проложить маршрут для каждой кв*/}
            {/* добавить конпку забронировать с шаблоном квартиы времени и даты */}
        </div>
    );
}

export default App;
